import { Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = {
  container: {
    width: "25%",
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  titleBackground: {
    width: "50%",
    backgroundColor: "#0E1A2A",
    borderColor: "#FE9A00",
    borderWidth: 0,
    borderTopWidth: 2,
    borderRightWidth: 2,
    paddingHorizontal: 8,
    paddingVertical: 2,
    marginBottom: 5,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    marginTop: 5,
  },
  detailText: {
    color: "#000",
    fontSize: 11,
    marginBottom: 3,
  },
  detailText1: {
    color: "#FFF",
    left: 0,
    top: 4,
    fontSize: 10,
    marginBottom: 5,
  },
};

const To = ({ customer }) => {
  return (
    <View style={styles.container}>
      <View style={styles.titleBackground}>
        <Text style={styles.detailText1}>Invoice For:</Text>
      </View>
      <Text style={styles.title}>{customer.name}</Text>
      <Text style={styles.detailText}>{customer.address}</Text>
      {customer.phone && (
        <Text style={styles.detailText}>Phone: {customer.phone}</Text>
      )}
      {customer.email && (
        <Text style={styles.detailText}>Email: {customer.email}</Text>
      )}
    </View>
  );
};

export default To;
