import React from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    height: 100,
    position: "relative",
  },
  leftRectangle: {
    width: "45%",
    backgroundColor: "#0E1A2A",
    height: "100%",
    zIndex: 1,
    borderWidth: 0,
  },
  innerRectangle: {
    position: 'absolute',
    top: '84%',
    backgroundColor: "#FE9A00",
    height: "16%",
    width: "101%",
  },
  slantOverlay: {
    position: "absolute",
    right: -13,
    top: 0,
    backgroundColor: "#0E1A2A",
    width: 40,
    height: "100%",
    transform: "skewX(-20deg)",
  },
  slantBorder: {
    position: "absolute",
    top: 0,
    left: "42%",
    backgroundColor: "#FE9A00",
    width: "7.7%",
    height: "100%",
    transform: "skewX(-20deg)",
    zIndex: 2,
  },
  slantBorder1: {
    position: "absolute",
    top: 0,
    left: "44%",
    backgroundColor: "rgba(254, 154, 0, 0.3)",
    width: "7.9%",
    height: "100%",
    transform: "skewX(-20deg)",
    zIndex: 2,
  },
  rightRectangle: {
    height: "70%",
    width: "70%",
    backgroundColor: "#142545",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    position: "absolute",
    right: 0,
    zIndex: 3,
  },
  invoiceTitle: {
    fontSize: 23,
    color: "#FE9A00",
    right: "18%",
    position: "absolute",
    top: "18%",
    textTransform: "uppercase",
  },
  invoiceId: {
    fontSize: 12,
    color: "#FFF",
    right: "18%",
    position: "absolute",
    top: "78%",
    textTransform: "uppercase",
  },
  logo: {
    position: 'absolute',
    top: '2%',
    left: '10%',
    width: '50%',
    height: '80%',
  },
});

const Header = ({ invoiceId, logo }) => (
  <View style={styles.headerContainer}>
    <View style={styles.leftRectangle}>
      <Image src={{uri: logo}} style={styles.logo} />
      <View style={styles.slantOverlay} />
      <View style={styles.innerRectangle} />
    </View>
    <View style={styles.slantBorder} />
    <View style={styles.slantBorder1} />
    <View style={styles.rightRectangle}>
      <Text style={styles.invoiceTitle}>Invoice</Text>
      <Text style={styles.invoiceId}>ID NO: {invoiceId}</Text>
    </View>
  </View>
);

export default Header;
