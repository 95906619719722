import React from "react";
import { View, Text, Font } from "@react-pdf/renderer";
import DejaVuSans from '../../assets/fonts/DejaVuSans.ttf';

Font.register({
  family: 'DejaVu Sans',
  src: DejaVuSans,
});

const styles = {
  rsfont : {
    fontFamily: 'DejaVu Sans',
  },
  tableContainer: {
    left: "3%",
    right: "5%",
    width: "90%",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 30,
    borderWidth: 0,
    borderColor: "#000",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    fontSize: 11,
    backgroundColor: "#FE9A00",
    borderColor: "#000",
    borderWidth: 0,
  },
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    fontSize: 10,
    backgroundColor: "#FE9A00",
    borderColor: "#000",
    borderWidth: 0,
  },
  headerItem: {
    flex: 1,
    textAlign: "center",
    position: "relative",
  },
  slantLine: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 3,
    height: "150%",
    backgroundColor: "#FFF",
    transform: "skewX(-20deg)",
  },
  tableRow: {
    fontSize: 9,
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
  },
  
  header1: {
    color: '#FFF',
    width: "56%",
    textAlign: "center",
    padding: 8,
  },
  header2: {
    marginTop: "1.5%",
    marginRight: "1.5%",
    left: '50%',
    color: '#FFF',
    width: "10%",
    textAlign: "left",
    padding: 0,
  },
  header3: {
    left: '25%',
    color: '#FFF',
    width: "8%",
    textAlign: "left",
    padding: 8,
  },
  header4: {
    left: '20%',
    color: '#FFF',
    width: "8%",
    textAlign: "left",
    padding: 8,
  },
  header5: {
    color: '#FFF',
    width: "15%",
    textAlign: "center",
    padding: 8,
  },

  column1: {
    width: "54%",
    textAlign: "center",
    padding: 8,
  },
  column2: {
    marginLeft: "5%",
    width: "10%",
    textAlign: "center",
    paddingTop: 8,
  },
  column3: {
    left: "15%",
    width: "10%",
    textAlign: "center",
    padding: 8,
  },
  column4: {
    left: "7%",
    width: "10%",
    textAlign: "center",
    padding: 8,
  },
  column5: {
    width: "18%",
    textAlign: "center",
    padding: 8,
  },
};

const TableHeader = () => (
  <View style={styles.tableHeader}>
    <Text style={styles.header1}>Title</Text>
    <View style={styles.headerItem}>
      <View style={styles.slantLine} />
    </View>

    <Text style={styles.header2}>Price</Text>
    <View style={styles.headerItem}>
      <View style={styles.slantLine} />
    </View>

    <Text style={styles.header3}>Qty</Text>
    <View style={styles.headerItem}>
      <View style={styles.slantLine} />
    </View>

    <Text style={styles.header4}>Dsc</Text>
    <View style={styles.headerItem}>
      <View style={styles.slantLine} />
    </View>

    <Text style={styles.header5}>Total</Text>
  </View>
);

const TableRow = ({ item }) => {
  const formatTitle = (title) => {
    if (title.length > 130) {
      return title.substring(0, 130) + '...';
    }
    return title;
  };
  return(
  <View style={styles.tableRow}>
    <Text style={styles.column1}>{formatTitle(item.name)}</Text>
    <Text style={styles.column2}><Text style={styles.rsfont}>₹</Text>{item.price}</Text>
    <Text style={styles.column3}>{item.quantity}</Text>
    <Text style={styles.column4}>{item.discount}%</Text>
    <Text style={styles.column5}><Text style={styles.rsfont}>₹</Text>{item.amount}</Text>
  </View>
  );
};

const Table = ({ items }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    {items.map((item, index) => (
      <TableRow key={index} item={item} />
    ))}
  </View>
);

export default Table;
