import React from 'react'
import { View, Text, Image } from "@react-pdf/renderer";

const styles = {
  signatureContainer: {
    width: "100%",
    marginTop: "50%",
    alignItems: 'flex-end',
    paddingRight: 30,
  },
  signatureImage: {
    width: 100,
    height: 50,
    marginBottom: 5,
  },
  signatureLine: {
    width: '70%',
    borderTopWidth: 1,
    borderTopColor: '#000',
    marginBottom: 5,
  },
  signatureText: {
    fontSize: 12,
    textAlign: 'center',
    marginRight: '15%',
  }
};

const Signature = ({ signatureImage }) => {
  return (
    <View style={styles.signatureContainer}>
      {signatureImage && <Image style={styles.signatureImage} src={signatureImage} />}
      <View style={styles.signatureLine} />
      <Text style={styles.signatureText}>Signature</Text>
    </View>
  )
}

export default Signature
