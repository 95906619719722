import React from "react";
import { View, Text, Font } from "@react-pdf/renderer";
import DejaVuSans from "../../assets/fonts/DejaVuSans.ttf";

Font.register({
  family: "DejaVu Sans",
  src: DejaVuSans,
});

const styles = {
  footerContainer: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
  },
  leftRectangle: {
    marginTop: "auto",
    backgroundColor: "#FE9A00",
    width: "50%",
    height: "10%",
    padding: 2,
    zIndex: 3,
    justifyContent: "center",
  },
  rightRectangle: {
    marginTop: "auto",
    backgroundColor: "#0E1A2A",
    width: "60%",
    height: "18.3%",
    flexDirection: "row",
    padding: 2,
    paddingLeft: "10%",
    marginLeft: "-3%",
    marginRight: 0,
    justifyContent: "center",
    zIndex: 1,
  },
  slantOverlay: {
    marginTop: "auto",
    marginBottom: "0",
    backgroundColor: "#0E1A2A",
    width: "7.7%",
    height: "18.3%",
    transform: "skewX(-20deg)",
    zIndex: 2,
  },
  contactRow: {
    marginLeft: 20,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2,
  },
  contactIcon: {
    fontSize: 8,
    marginRight: 2,
  },
  contactLabel: {
    fontSize: 8,
    marginRight: 2,
    color: "#FFF",
  },
  contactText: {
    fontSize: 8,
    color: "#FFF",
  },
};

const From = ({ownDetails}) => {
  return (
    <View style={styles.footerContainer}>
      <View style={styles.leftRectangle}></View>
      <View style={styles.slantOverlay} />
      <View style={styles.rightRectangle}>
        <View style={styles.contactRow}>
          <Text style={styles.contactLabel}>Phone:</Text>
          <Text style={styles.contactText}>{ownDetails.phone}</Text>
        </View>
        <View style={styles.contactRow}>
          <Text style={styles.contactLabel}>Email:</Text>
          <Text style={styles.contactText}>{ownDetails.email}</Text>
        </View>
      </View>
      
    </View>
  );
};

export default From;
