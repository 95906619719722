import React from "react";
import { View, Text, Font } from "@react-pdf/renderer";
import DejaVuSans from '../../assets/fonts/DejaVuSans.ttf';

Font.register({
  family: 'DejaVu Sans',
  src: DejaVuSans,
});

const styles = {

  rsfont : {
    fontFamily: 'DejaVu Sans',
  },

  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "28%",
    paddingLeft: "2%",
    paddingRight: "2%",
    marginLeft: "63%",
    marginTop: 10,
    marginRight: "10%",
  },
  label: {
    fontSize: 10,
    color: "#000",
    width: "30%",
    textAlign: "left",
  },
  value: {
    fontSize: 10,
    color: "#000",
    width: "70%",
    textAlign: "right",
  },
  label1: {
    fontSize: 10,
    color: "#FFF",
    width: "30%",
    textAlign: "left",
  },
  value1: {
    fontSize: 10,
    color: "#FFF",
    width: "70%",
    textAlign: "right",
  },
  totalRow: {
    borderWidth: 0,
    paddingTop: "0.3%",
    paddingBottom: "0.3%",
    backgroundColor: "#FE9A00",
  },
  slantLine: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: "60%",
    backgroundColor: "transparent",
    borderRight: "2px solid white",
    transform: "skewX(-20deg)",
  },
};

const Total = ({ subtotal, tax, total }) => {
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.label}>Subtotal</Text>
        <Text style={styles.value}><Text style={styles.rsfont}>₹</Text> {subtotal}</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.label}>Tax</Text>
        <Text style={styles.value}>{tax} %</Text>
      </View>
      <View style={[styles.container, styles.totalRow]}>
        <View style={styles.slantLine} />
        <Text style={styles.label1}>Total</Text>
        <Text style={styles.value1}><Text style={styles.rsfont}>₹</Text> {total}</Text>
      </View>
    </>
  );
};

export default Total;
