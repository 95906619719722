import React from "react";
import { View, Text } from "@react-pdf/renderer";

const styles = {
  container: {
    marginTop: 40,
    padding: 10,
    marginLeft: '2%',
    marginRight: '15%',
    width: '100%',
    borderWidth: 1,
    borderColor: '#000',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center',
  },
  term: {
    fontSize: 6,
    padding: 5,
    textAlign: 'left',
  },
};

const Terms = ({ terms }) => {
  return (
    <View style={styles.container}>
      {terms.map((term, index) => (
        <View key={index} style={[styles.row, { borderBottomWidth: index === terms.length - 1 ? 0 : 1 }]}>
          <Text style={styles.term}>{term}</Text>
        </View>
      ))}
    </View>
  );
};

export default Terms;