import React from "react";
import { Page, Document, PDFViewer, View } from "@react-pdf/renderer";
import {
  Header,
  To,
  Table,
  Total,
  Terms,
  Signature,
  From,
} from "../../containers";
import { useLocation } from "react-router-dom";

const MAX_ITEMS_PER_PAGE = 7;

const styles = {
  page: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "#FFF",
  },
  document: {
    width: "100%",
    height: "100vh",
  },
  body: {
    flexGrow: 1,
    paddingBottom: 60,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  terms: {
    width: "70%",
  },
  signature: {
    width: "30%",
  },
};

const IDocument = () => {
  const location = useLocation();
  const data = location.state?.invoiceData;

  if (!data) {
    return <div>Loading or no data...</div>;
  } else {
    const customerDetails = data.clientDetails;

    const ownDetails = data.ownDetails;
    const terms = data.terms ? data.terms.slice(0, 5) : [];

    const products = data.products || [];
    let total = 0;
    let amtTotal = 0;

    const pages = [];
    if (Array.isArray(products) && products.length > 0) {
      for (let i = 0; i < products.length; i += MAX_ITEMS_PER_PAGE) {
        const pageItems = products.slice(i, i + MAX_ITEMS_PER_PAGE);
        pages.push(pageItems);
      }
      for (let j = 0; j < products.length; j++) {
        let product = products[j];
        total = total + parseInt(product.amount, 10);
      }
      amtTotal = total + total * 0.18;
      total = total.toFixed(2);
      amtTotal = amtTotal.toFixed(2);
    }

    return (
      <PDFViewer style={styles.document}>
        <Document>
          {pages.map((pageItems, index) => (
            <Page size="A4" style={styles.page} key={index}>
              <View style={styles.body}>
                <Header invoiceId={data.invoiceId} logo={data.logo} />
                {index === 0 && <To customer={customerDetails} />}
                <Table items={pageItems} />
                {index === pages.length - 1 && (
                  <>
                    <Total subtotal={total} tax={"18"} total={amtTotal} />
                    <View style={styles.row}>
                      <View style={styles.terms}>
                        <Terms terms={terms} />
                      </View>
                      <View style={styles.signature}>
                        <Signature signatureImage={data.signature} />
                      </View>
                    </View>
                  </>
                )}
              </View>
              <From ownDetails={ownDetails} />
            </Page>
          ))}
        </Document>
      </PDFViewer>
    );
  }
};

export default IDocument;
