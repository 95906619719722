import React, { useState } from "react";
import {
  Container,
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Add, DeleteOutline, PhotoCamera } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const navigate = useNavigate();

  const [terms, setTerms] = useState([""]);

  const handleAddTerm = () => {
    if (terms.length < 5) {
      setTerms([...terms, ""]);
    }
  };

  const handleRemoveTerm = (index) => {
    setTerms(terms.filter((_, i) => i !== index));
  };

  const handleTermChange = (index, value) => {
    const updatedTerms = terms.map((term, i) => (i === index ? value : term));
    setTerms(updatedTerms);
  };

  const [products, setProducts] = useState([
    { name: "", price: "", quantity: "", discount: "", amount: "" },
  ]);

  const [invoiceId, setInvoiceId] = useState("");

  const [clientDetails, setClientDetails] = useState({
    name: "",
    businessName: "",
    phone: "",
    address: "",
  });

  const handleClientDetailsChange = (field, value) => {
    setClientDetails({ ...clientDetails, [field]: value });
  };

  const [ownDetails, setOwnDetails] = useState({
    name: "",
    businessName: "",
    phone: "",
    address: "",
    email: "",
  });

  const handleOwnDetailsChange = (field, value) => {
    setOwnDetails({ ...ownDetails, [field]: value });
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = products.map((product, i) => {
      if (index === i) {
        const updatedProduct = { ...product, [field]: value };

        // Update amount if price, quantity, or discount changes
        if (field === "price" || field === "quantity" || field === "discount") {
          const amount = calculateAmount(
            updatedProduct.price,
            updatedProduct.quantity,
            updatedProduct.discount
          );
          updatedProduct.amount = amount;
        }

        return updatedProduct;
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      { name: "", price: "", quantity: "", discount: "", amount: "" },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
  };

  const calculateAmount = (price, quantity, discount) => {
    const discountAmount = (price * quantity * (discount / 100)).toFixed(2);
    return (price * quantity - discountAmount).toFixed(2);
  };

  const [logoPreview, setLogoPreview] = useState("");
  const [signaturePreview, setSignaturePreview] = useState("");

  const handleImageChange = (event, setPreview) => {
    const file = event.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const invoiceData = {
      invoiceId,
      products,
      clientDetails,
      ownDetails,
      logo: logoPreview,
      signature: signaturePreview,
      terms
    };
    navigate("/idocument", { state: { invoiceData: invoiceData } });
  };

  return (
    <div>
      <Container maxWidth="md" component="main">
        <Typography
          variant="h4"
          align="center"
          marginY={2}
          style={{ color: "#527DF3" }}
        >
          Invoice Form
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          {/* Invoice ID */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="invoice-id"
              label="Invoice ID"
              name="invoiceId"
              value={invoiceId}
              onChange={(e) => setInvoiceId(e.target.value)}
              style={{ marginTop: "50px" }}
            />
          </Grid>

          {/* Blocks for Client and Own Details */}
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            justifyContent="center"
            style={{ marginTop: "30px" }}
          >
            {/* Client Details Block */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Client Details</Typography>
                <TextField
                  required
                  fullWidth
                  id="client-name"
                  label="Client Name"
                  name="clientName"
                  value={clientDetails.name}
                  onChange={(e) =>
                    handleClientDetailsChange("name", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  fullWidth
                  id="client-business-name"
                  label="Business Name"
                  name="clientBusinessName"
                  value={clientDetails.businessName}
                  onChange={(e) =>
                    handleClientDetailsChange("businessName", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  required
                  fullWidth
                  id="client-phone"
                  label="Phone"
                  name="clientPhone"
                  value={clientDetails.phone}
                  onChange={(e) =>
                    handleClientDetailsChange("phone", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  required
                  fullWidth
                  id="client-address"
                  label="Address"
                  name="clientAddress"
                  value={clientDetails.address}
                  onChange={(e) =>
                    handleClientDetailsChange("address", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
              </Paper>
            </Grid>

            {/* Own Details Block */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Your Details</Typography>
                <TextField
                  required
                  fullWidth
                  id="own-name"
                  label="Your Name"
                  name="ownName"
                  value={ownDetails.name}
                  onChange={(e) =>
                    handleOwnDetailsChange("name", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  fullWidth
                  id="own-business-name"
                  label="Business Name"
                  name="ownBusinessName"
                  value={ownDetails.businessName}
                  onChange={(e) =>
                    handleOwnDetailsChange("businessName", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  required
                  fullWidth
                  id="own-phone"
                  label="Phone"
                  name="ownPhone"
                  value={ownDetails.phone}
                  onChange={(e) =>
                    handleOwnDetailsChange("phone", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  required
                  fullWidth
                  id="own-address"
                  label="Address"
                  name="ownAddress"
                  value={ownDetails.address}
                  onChange={(e) =>
                    handleOwnDetailsChange("address", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
                <TextField
                  required
                  fullWidth
                  id="own-email"
                  label="Email"
                  name="ownEmail"
                  value={ownDetails.email}
                  onChange={(e) =>
                    handleOwnDetailsChange("email", e.target.value)
                  }
                  style={{ marginTop: 8 }}
                />
              </Paper>
            </Grid>

            {/* Logo and Signature Block */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h6">Logo</Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  startIcon={<PhotoCamera />}
                >
                  Upload Logo
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, setLogoPreview)}
                  />
                </Button>
                {logoPreview && (
                  <Box mt={2} sx={{ textAlign: "center" }}>
                    <img
                      src={logoPreview}
                      alt="Logo Preview"
                      style={{ maxHeight: "100px" }}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                <Typography variant="h6">Signature</Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  component="label"
                  startIcon={<PhotoCamera />}
                >
                  Upload Signature
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, setSignaturePreview)}
                  />
                </Button>
                {signaturePreview && (
                  <Box mt={2} sx={{ textAlign: "center" }}>
                    <img
                      src={signaturePreview}
                      alt="Signature Preview"
                      style={{ maxHeight: "100px" }}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            justifyContent="center"
            style={{ marginTop: 16 }}
          >
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" style={{ marginBottom: "40px" }}>
                  Products
                </Typography>

                {products.map((product, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    style={{ marginBottom: "20px" }}
                  >
                    <Grid item xs={3}>
                      <TextField
                        required
                        fullWidth
                        id={`product-name-${index}`}
                        label="Product Name"
                        name="productName"
                        value={product.name}
                        onChange={(e) =>
                          handleProductChange(index, "name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        required
                        fullWidth
                        id={`product-price-${index}`}
                        label="Price"
                        name="productPrice"
                        type="number"
                        value={product.price}
                        onChange={(e) =>
                          handleProductChange(index, "price", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        required
                        fullWidth
                        id={`product-quantity-${index}`}
                        label="Quantity"
                        name="productQuantity"
                        type="number"
                        value={product.quantity}
                        onChange={(e) =>
                          handleProductChange(index, "quantity", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        id={`product-discount-${index}`}
                        label="Discount"
                        name="productDiscount"
                        type="number"
                        value={product.discount}
                        onChange={(e) =>
                          handleProductChange(index, "discount", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        fullWidth
                        id={`product-amount-${index}`}
                        label="Amount"
                        name="productAmount"
                        value={product.amount}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={() => handleRemoveProduct(index)}
                        style={{ color: "red" }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={handleAddProduct}
                  sx={{ mt: 2 }}
                >
                  Add Product
                </Button>
              </Paper>
            </Grid>
          </Grid>

          {/* Terms */}
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
              <Typography variant="h6" style={{ marginBottom: "20px" }}>
                Terms
              </Typography>
              {terms.map((term, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 2 }}
                >
                  <TextField
                    fullWidth
                    value={term}
                    onChange={(e) => handleTermChange(index, e.target.value)}
                    label={`Term ${index + 1}`}
                  />
                  <IconButton
                    onClick={() => handleRemoveTerm(index)}
                    style={{ color: "red", ml: 2 }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Box>
              ))}
              {terms.length < 5 && (
                <Button
                  variant="outlined"
                  onClick={handleAddTerm}
                  sx={{ mt: 2 }}
                >
                  Add Term
                </Button>
              )}
            </Paper>
          </Grid>

          {/* Submit Button */}
          <Grid
            container
            justifyContent="center"
            style={{ marginTop: 50, marginBottom: 50 }}
          >
            <Button type="submit" variant="contained" color="success">
              Submit
            </Button>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Create;
