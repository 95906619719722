import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Create, IDocument } from "./components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { colors } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: colors.lightBlue[500],
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
  },
});

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Create />} />
            <Route path="/idocument" element={<IDocument />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
